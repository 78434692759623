import React, { useEffect, useState } from 'react';

function Shutter() {
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    // Simulate scrolling up after a delay (e.g., 3 seconds)
    const scrollTimer = setTimeout(() => {
      setScrolling(true);
    }, 3000); // Adjust the delay as needed in milliseconds

    return () => {
      clearTimeout(scrollTimer); // Clear the timeout to avoid unexpected behavior
    };
  }, []);

  return (
    <div className="App">
      <div className={`section ${scrolling ? 'scrolling' : ''}`}>
        <h1>First Section</h1>
        {/* Content for the first section */}
      </div>
      <div className="section">
        <h1>Second Section</h1>
        {/* Content for the second section */}
      </div>
    </div>
  );
}

export default Shutter;
