import { Grid, Typography } from '@mui/material'
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-flip';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { EffectFlip, Pagination, Navigation } from 'swiper';
import './Sample1.css';
import { serverURL } from '../Services/NodeServices';



// import required modules

const SampleMenu1 = ({images}) => {
  
    console.log(images)
   

  return (
    <Grid>
        <Swiper
        ca
        effect={'flip'}
        grabCursor={true}
        pagination={true}
        navigation={true}
        modules={[EffectFlip, Pagination, Navigation]}
        className="mySwiper"
      >
        {
             images.map((item)=>{
                return(
                    <SwiperSlide>
                    <img src={`${serverURL}/images/${item}`} />
                  </SwiperSlide>
                )
            })
        }
             </Swiper>
       
    </Grid>
  )
}

export default SampleMenu1
