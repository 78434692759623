import { Grid, Button, Typography, TextField, Avatar, IconButton, useTheme, useMediaQuery, Select } from '@mui/material'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { postData, serverURL } from '../Services/NodeServices'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import BeenhereIcon from '@mui/icons-material/Beenhere';
import Swal from 'sweetalert2'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

const AddMenu = () => {

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down(600));
  const tablet = useMediaQuery(theme.breakpoints.down(960));

  const navigate = useNavigate()
  const [productData, setProductData] = useState(Array(4).fill(null));
  const [selectedImages, setSelectedImages] = useState(Array(4).fill(null));

  const [themeId, setThemeId] = React.useState('');
  const [name, setName] = React.useState('');
  const [link, setLink] = React.useState('');

  const handleChange = (event) => {
    setThemeId(event.target.value);
  };
  const handleImageChange = (index, event) => {
    const files = event.target.files;
    if (files.length > 0) {
      const selectedImage = files[0];
      const updatedSelectedImages = [...selectedImages];
      updatedSelectedImages[index] = selectedImage;
      setSelectedImages(updatedSelectedImages);
    }
  };
  
  const handleAdd=()=>{

   

    if(selectedImages[0]!=null && selectedImages[1]!=null && selectedImages[2]!=null && selectedImages[3]!=null){
    
    
      setSelectedImages([...selectedImages,...Array(1).fill(null)])
      setProductData([...productData,...Array(1).fill(null)])
   }else {
      Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Fill The Empty Columns',
          showConfirmButton: false,
          timer: 1500
      })
   }
  }

  const handleSubmit=async()=>{
    const formData = new FormData();
formData.append('themeId', themeId);
formData.append('name', name);
selectedImages.forEach((image, index) => {
  if (image) {
    formData.append(`images[${index}]`, image);
  }

 
});

const response=await postData('menu/addmenu',formData,true)
console.log(response.data)
setLink(`https://samplemenu.digitalcardhub.in/#/${response.data.uniqueId}`)
  }

  const handleCopy=()=>{
   

   // Copy the text inside the text field
  navigator.clipboard.writeText(link);

  
  }

  return (
    <Grid>

      <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', paddingX: "20px" }}>
        
        <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', width: '90%' }}>
          <Grid item xs={12}>
            <Typography textAlign='center' sx={{ fontSize: { xs: "1.4rem", md: 28 }, fontWeight: 'bold', mt: mobile ? "2vh" : tablet ? "2vh" : "" }}>Add Menu</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField textAlign='center' onChange={(e)=>setName(e.target.value)} fullWidth sx={{ fontSize: { xs: "1.4rem", md: 28 }, fontWeight: 'bold', mt: mobile ? "2vh" : tablet ? "2vh" : "" }} label="Name" />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Theme</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={themeId}
                label="Theme"
                onChange={handleChange}
              >
                <MenuItem value={"Theme1"}>Theme1</MenuItem>
                <MenuItem value={"Theme2"}>Theme2</MenuItem>
                <MenuItem value={"Theme3"}>Theme3</MenuItem>
                <MenuItem value={"Theme3"}>Theme4</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {productData.map((item, index) => {
            return (<Grid
              key={index}
              item
              xs={mobile ? 12 : tablet ? 5 : 2.5}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                border: 1,
                marginLeft: 4,
                marginTop: 4,
                paddingX: "15px"
              }}
            >
              <Grid container spacing={2} style={{ display: "flex", height: 60 }}>
                <Grid item xs={9} style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography >{index + 1}</Typography>
                </Grid>
               
              </Grid>
              <Button
                style={{ display: 'flex', flexDirection: 'column', fontSize: 12.5, fontWeight: 'bold' }}
                variant="text"
                component="label"
              >
                {/* {alert(JSON.stringify(productData[index]))} */}
                <Avatar
                  fullWidth
                  variant="rounded"
                  alt="Remy Sharp"
                  src={selectedImages[index] ? URL.createObjectURL(selectedImages[index]) : ''}
                  sx={{ width: 100, height: 100 }}
                />
                Upload Product Image
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={(event) => handleImageChange(index, event)}
                />
              </Button>
            </Grid>
            )
          })

          }
           <Grid item xs={12} style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
            <Button sx={{
              borderRadius: 10,
              backgroundImage: "linear-gradient(to top left,#48dbfb,#001e3c)",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              paddingX:"30px",
              textAlign: "center",
              alignItems: "center",
            }} variant='contained' onClick={() => handleAdd()}>Add More Products</Button>
          </Grid>

          
          <Grid item xs={12} style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
            <Button sx={{
              borderRadius: 10,
              backgroundImage: "linear-gradient(to top left,#48dbfb,#001e3c)",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              paddingX: "30px",
              textAlign: "center",
              alignItems: "center",
            }} variant='contained'  onClick={()=>handleSubmit()}>Submit</Button>
          </Grid>


        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography>{link}</Typography>
        </Grid>
       {link && <Grid item xs={6} style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
            <Button sx={{
              borderRadius: 10,
              backgroundImage: "linear-gradient(to top left,#48dbfb,#001e3c)",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              paddingX:"30px",
              textAlign: "center",
              alignItems: "center",
            }} variant='contained' onClick={() => handleCopy()}>Copy</Button>
          </Grid>}


      </Grid>


    </Grid>
  )
}

export default AddMenu
