import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-creative';

import './Sample4.css';

// import required modules
import { EffectCreative } from 'swiper';
import { Grid } from '@mui/material';
import { serverURL } from '../Services/NodeServices';

const Sample4Menu = ({images}) => {
  return (
    <Grid>
         <Swiper
        grabCursor={true}
        effect={'creative'}
        creativeEffect={{
          prev: {
            shadow: true,
            origin: 'left center',
            translate: ['-5%', 0, -200],
            rotate: [0, 100, 0],
          },
          next: {
            origin: 'right center',
            translate: ['5%', 0, -200],
            rotate: [0, -100, 0],
          },
        }}
        modules={[EffectCreative]}
        className="mySwiper6"
      >
        {
             images.map((item)=>{
                return(
                    <SwiperSlide>
                    <img src={`${serverURL}/images/${item}`} />
                  </SwiperSlide>
                )
            })
        }
      </Swiper>
    </Grid>
  )
}

export default Sample4Menu
