import React from 'react';
// Import Swiper styles
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cards';
// import required modules
import { EffectCards } from 'swiper';
import './Sample2.css';
import { Grid } from '@mui/material';
import { serverURL } from '../Services/NodeServices';




const Sample2Menu = ({images}) => {
  return (
    <Grid sx={{overflowX:'scroll',height:'100%'}}>
         <Swiper
        effect={'cards'}
        grabCursor={true}
        modules={[EffectCards]}
        
        className="mySwiper"
      >
        {
             images.map((item)=>{
                return(
                    <SwiperSlide style={{height:500}}>
                    <img src={`${serverURL}/images/${item}`}/>
                  </SwiperSlide>
                )
               }
             )}
      </Swiper>
    
    </Grid>
  )
}

export default Sample2Menu
