import React from 'react';
import { BrowserRouter as Router,Routes,Route } from "react-router-dom";
import { HashRouter } from 'react-router-dom';
import SampleMenu1 from './Samples/SampleMenu1';
import Sample2Menu from './Samples/Sample2Menu';
import Sample3Menu from './Samples/Sample3Menu';
import Sample4Menu from './Samples/Sample4Menu';
import Demo1 from './Samples/Demo1';
import Shutter from './Samples/Shutter';
import AddMenu from './Administration/AddMenu';
import Menu from './Administration/Menu';
function App() {
  return (
    <div>
      <HashRouter>
        <Routes>
          <Route element={<SampleMenu1/>} path="/s1"/>
          <Route element={<Demo1/>} path="/demo"/>
          <Route element={<Sample2Menu/>} path="/s2"/>
          <Route element={<Sample3Menu/>} path="/s3"/>
          <Route element={<Sample4Menu/>} path="/s4"/>
          <Route element={<Shutter/>} path="/s5"/>
          <Route element={<AddMenu/>} path="/addMenu"/>
          <Route element={<Menu/>} path="/:uniqueId"/>
        </Routes>
      </HashRouter>

    </div>
   
  );
}

export default App;
