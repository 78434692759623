import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import './Sample3.css';

// import required modules
import { EffectFade, Navigation, Pagination } from 'swiper';
import { Grid } from '@mui/material';
import { serverURL } from '../Services/NodeServices';

const Sample3Menu = ({images}) => {
  return (
   <Grid>
     <Swiper
        spaceBetween={30}
        effect={'fade'}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        modules={[EffectFade, Navigation, Pagination]}
        className="mySwiper"
      >
         {
             images.map((item)=>{
                return(
                    <SwiperSlide>
                    <img src={`${serverURL}/images/${item}`} />
                  </SwiperSlide>
                )
            })
        }
      </Swiper>
   </Grid>
  )
}

export default Sample3Menu
