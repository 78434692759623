import React, { useEffect, useState } from 'react'
import { postData } from '../Services/NodeServices';
import Sample2Menu from '../Samples/Sample2Menu';
import { useParams } from 'react-router-dom';
import SampleMenu1 from '../Samples/SampleMenu1';
import Sample3Menu from '../Samples/Sample3Menu';
import Sample4Menu from '../Samples/Sample4Menu';

const Menu = () => {
    const { uniqueId } = useParams();
    const [data,setData]=useState([])

    const fetchMenu=async()=>{
     var formData=new FormData
     formData.append("uniqueId",uniqueId)
     var res=await postData('menu/getMenubyId',formData,true)
     console.log(res.data.images)
     setData(res.data)

    }

    useEffect(()=>{
        fetchMenu()
    },[])

  return (
    <>
    {data.themeId=="Theme1"?<SampleMenu1 images={data.images}/>:data.themeId=="Theme2"?<Sample2Menu images={data.images}/>:data.themeId=="Theme3"?<Sample3Menu images={data.images}/>:data.themeId=="Theme4"?<Sample4Menu images={data.images}/>:<></>
}
    </>
  )
}

export default Menu
