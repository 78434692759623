import MaterialTable from '@material-table/core';
import { Button, Card, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography, duration } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getData, postData } from '../Services/NodeServices';
import CountdownTimer from './CountdownTimer';

const Demo1 = () => {

  const [timeDuration, setTimeDuration] = useState('');
  const [contestName,setContestName]=useState('')
  const [contests,setContests]=useState([])


  const handleChange = (e) => {
    setTimeDuration(e.target.value)
  }
  const addContest=async()=>{
  
    const cureentTime=new Date();

   
   const res=await postData('demo/addcontest',{"contestName":contestName,"duration":timeDuration,"startingTime":cureentTime.toLocaleTimeString(),"startingDate":cureentTime.toLocaleString()})
   
   fetchAllContest()
    

  }

  const fetchAllContest=async()=>{
    const result=await getData('demo/displayallcontests')
    setContests(result.data.reverse())
  }

  useEffect(()=>{
   fetchAllContest()
  },[])

  const Countdown=(min)=>{
    const endTime = new Date().getTime() - min * 60000; // Calculate the end time by subtracting minutes in milliseconds
    const timerElement = document.getElementById("timer"); // Replace "timer" with the ID of the HTML element where you want to display the countdown
  
    function updateTimer() {
      const currentTime = new Date().getTime();
      const remainingTime = endTime - currentTime;
  
      if (remainingTime <= 0) {
        clearInterval(interval);
        timerElement.innerHTML = "Countdown expired!";
      } else {
        const seconds = Math.floor((remainingTime % 60000) / 1000);
        const displayMinutes = Math.floor(remainingTime / 60000);
        const displaySeconds = seconds < 10 ? "0" + seconds : seconds;
  
        timerElement.innerHTML = `${displayMinutes}:${displaySeconds}`;
      }
    }
  
    updateTimer(); // Initial display
    const interval = setInterval(updateTimer, 1000);
  }

  const YesVotes=async(item)=>{

    const result=await postData('demo/addYes',{"_id":item._id,"yes":item.yesVotes+1})

    fetchAllContest()

  }
  const NoVotes=async(item)=>{

    const result=await postData('demo/addNo',{"_id":item._id,"no":item.noVotes+1})

    fetchAllContest()

  }

  const ContestComponent=()=>{
    return contests.map((item,index)=>{
      const dbDate=new Date(item.startingDate)
      const timeDb=item.duration
      const aftertime=new Date(dbDate.getTime()+timeDb*60000)
      const currtime=new Date()
      const differenceInMilliseconds = aftertime - currtime;
const differenceInMinutes = differenceInMilliseconds / (1000 * 60);
   
      return(<>
        { aftertime>currtime?
        <Grid item xs={4} sx={{ display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: 'column', }}>
             <Paper elevation={3} sx={{width:"80%",height:150}}>
             <Grid container spacing={2} sx={{ display: "flex", justifyContent: "center", alignItems: 'center'}}>
              
              <Grid item xs={7}>
                  Contest Name:{item.contestName}
              </Grid>
              <Grid item xs={5}>
              <CountdownTimer minutes={differenceInMinutes}/>     
              </Grid>
              <Grid item xs={12} textAlign={'center'}>
                Vote Here
              </Grid>
              <Grid item xs={6} sx={{ display: "flex", justifyContent: "center", alignItems: 'center'}}>
                  <Button variant='contained' onClick={()=>YesVotes(item)}>Yes</Button>
              </Grid>
              <Grid item xs={6} sx={{ display: "flex", justifyContent: "center", alignItems: 'center'}}>
                  <Button variant='contained' onClick={()=>NoVotes(item)}>No</Button>
              </Grid>
             </Grid>
             </Paper>
        </Grid>:<Grid item xs={4} sx={{ display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: 'column', }}>
             <Paper elevation={3} sx={{width:"80%",height:150}}>
             <Grid container spacing={2} sx={{ display: "flex", justifyContent: "center", alignItems: 'center'}}>
              
              <Grid item xs={7}>
                  Contest Name:{item.contestName}
              </Grid>
             
              <Grid item xs={5}>
              Game Over
              </Grid>
              {item.yesVotes>item.noVotes?<Grid item xs={12} textAlign={'center'}>
                No Wins
              </Grid>:<Grid item xs={12} textAlign={'center'}>
                 Yes Wins
              </Grid>
    }
             </Grid>
             </Paper>
        </Grid>}</>
      )
    })
  }
 
  return (
    <Grid>
      <Grid container spacing={2} sx={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
          <Typography textAlign={'center'}>Make A Contest</Typography>
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: 'column' }}>
          <Typography textAlign={'center'}>Contest Name</Typography>
          <TextField label='Contest Name' value={contestName} onChange={(e)=>setContestName(e.target.value)} />
        </Grid>
        <Grid item xs={6} sx={{ display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: 'column' }}>
          <Typography textAlign={'center'}>Contest Duration</Typography>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Duration</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={timeDuration}
              label="Duration"
              onChange={handleChange}
            >
              <MenuItem value={1}>One Minute</MenuItem>
              <MenuItem value={5}>Five Minute</MenuItem>
              <MenuItem value={10}>Ten Minute</MenuItem>
            </Select>
          </FormControl>


        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: 'column' }}>
          <Button variant='contained' onClick={()=>addContest()}>Start Contest</Button>
        </Grid>
      

      </Grid>
      <Grid container spacing={2} sx={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
          <Typography textAlign={'center'}>Running Contest</Typography>
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: 'column' }}>
        <MaterialTable
        title={"Contest"}
        data={contests}
        style={{}}
        columns={[
          // {
          //   title: "Card Status",
          //   render: (rowData) => (
          //     <div>
          //       <Switch
          //         checked={rowData.cardStatus=="Active"?true:false}
          //         onChange={()=>handleChange(rowData._id,rowData.cardStatus)}
          //         inputProps={{ "aria-label": "controlled" }}
          //       />
          //     </div>
          //   ),
          // },
          
          {
            title: "Company Name",
            field: "contestName",
          },
          {
            title: "Name",
            field: "duration",
          },
          {
            title: "Starting Time",
            field: "startingTime",
          },
          {
            title: "Starting Date",
            field: "startingDate",
          },
          {
            title: "Yes Votes",
            field: "yesVotes",
          },
          {
            title: "No Votes",
            field: "noVotes",
          },
          
          
          // {
          //   title: "Card Details",
          //   render: (rowData) => (
          //     <div>
          //       <Button variant="contained" onClick={() => handleOpen(rowData)}>
          //         Show
          //       </Button>
          //     </div>
          //   ),
          // },
        ]}
        actions={[]}
      />
        </Grid>
             </Grid>
      <Grid container spacing={2} sx={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
          <Typography textAlign={'center'}>Contests</Typography>
        </Grid>

        <ContestComponent/>
       
             </Grid>

    </Grid>

  )
}

export default Demo1
