import React, { useEffect, useState } from 'react';

const CountdownTimer = ({ minutes }) => {
  const [seconds, setSeconds] = useState(minutes * 60);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds]);

  const displayMinutes = Math.floor(seconds / 60);
  const displaySeconds = Math.floor(seconds % 60);

  return (
    <div>
      
      <p>{displayMinutes<0?'00:00':`${displayMinutes}:${displaySeconds < 10 ? '0' : ''}${displaySeconds}`}</p>
    </div>
  );
};

export default CountdownTimer;
